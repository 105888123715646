import React from 'react';
import type AddModel from './model';
import { FullModal, singleUploadObs } from '../../../../utils';
import { observer } from 'mobx-react';
import styles from './index.less';
import { Button, Card, Cascader, Col, Form, Image, Input, message, Row, Select, Space, Transfer, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { uniqueId } from 'lodash';
import type { GetProp, UploadProps } from 'antd';
import compressImgOrVideo from '../../../../utils/compress';

const OBS_PATH = 'community/avatar/image/';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('仅支持上传JPG、PNG!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('图片必须小于5MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};
@observer
export default class Add extends React.Component<{ store: AddModel }> {
  render() {
    const { visible, editId, onSubmit, clearPriceTemplate, parentStore, anchorList, getPriceCategoryName, detailList, loading, huaWeiObsConfig, onCancel, formRef } = this?.props?.store || {};

    return (
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        ref={formRef}
      >
        <FullModal
          title={editId ? '编辑社群' : '添加社群'}
          visible={visible}
          onCancel={onCancel}
          operation={
            <Button
              onClick={onSubmit}
              type="primary"
            >
              保存
            </Button>
          }
        >
          <div className={styles.content}>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="firstCategoryId"
                  label="一级类别"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="请选择"
                    onChange={(value) => {
                      clearPriceTemplate();
                      formRef.current?.setFieldsValue({ secondCategoryId: undefined });
                      parentStore.getCategorySecond(value);
                    }}
                    options={parentStore.firsList}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondCategoryId"
                  label="二级类别"
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={() => {
                      clearPriceTemplate();
                    }}
                    placeholder="请选择"
                    options={parentStore.secondList}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="cityId"
                  label="群区域"
                  rules={[{ required: true }]}
                >
                  <Cascader
                    onChange={() => {
                      clearPriceTemplate();
                    }}
                    placeholder="请选择"
                    options={parentStore.cityList}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="managerId"
                  label="管理员"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={parentStore.userList}
                    placeholder="请选择"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="群名称"
              rules={[{ required: true }]}
              name="groupName"
            >
              <Input maxLength={15} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="群价格"
              shouldUpdate
              required
            >
              {({ getFieldsValue, setFieldValue }) => {
                const { firstCategoryId, secondCategoryId, cityId } = getFieldsValue();
                return (
                  <div>
                    <Space>
                      <Button
                        onClick={() => {
                          const [parentRegionId, regionId] = cityId;
                          parentStore.getPriceTemplateList({
                            parentCategoryId: firstCategoryId,
                            categoryId: secondCategoryId,
                            parentRegionId,
                            regionId,
                          });
                          parentStore.setPriceTemplateOpen();
                        }}
                        disabled={!firstCategoryId || !secondCategoryId || !cityId}
                        type="link"
                      >
                        导入模版
                      </Button>
                      <Button
                        disabled={!firstCategoryId || !secondCategoryId || !cityId}
                        type="link"
                        onClick={() => {
                          parentStore.setPriceOpen();
                        }}
                      >
                        独立设置
                      </Button>
                    </Space>
                    {detailList.length ? (
                      <Card className={styles.card}>
                        {detailList.map((item, index) => (
                          <Space
                            className={styles.detailListItem}
                            key={`${index.toString()}detailList`}
                          >
                            标题
                            <span>{item.title}</span>
                            会员时长
                            <span className={styles.duration}>{item.duration}</span>
                            {getPriceCategoryName(item.priceCategory)}
                            价格
                            <span className={styles.price}>{item.price}元</span>
                          </Space>
                        ))}
                      </Card>
                    ) : undefined}
                  </div>
                );
              }}
            </Form.Item>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="群简介"
              name="groupDesc"
              rules={[{ required: true }]}
            >
              <Input.TextArea maxLength={20} />
            </Form.Item>
            <Form.Item
              label="群头像"
              required
              shouldUpdate
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              className={styles.formItem}
            >
              {({ getFieldValue, setFieldValue }) => {
                const groupAvatar = getFieldValue('groupAvatar');
                return (
                  <Form.Item
                    className={styles.formItem}
                    name="groupAvatar"
                    getValueFromEvent={(e) => groupAvatar}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: '请上传群头像',
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      beforeUpload={beforeUpload}
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={async (i: any) => {
                        const unit = i.file.name.split(',');
                        const file: any = await compressImgOrVideo(i.file);
                        if (file === false) {
                          return;
                        }
                        const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                        setFieldValue('groupAvatar', url);
                      }}
                    >
                      {groupAvatar && typeof groupAvatar === 'string' ? (
                        <img
                          src={groupAvatar}
                          alt="avatar"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      ) : (
                        <button
                          style={{
                            border: 0,
                            background: 'none',
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>上传</div>
                        </button>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div className={styles.tips}>
              支持JPG、PNG，大小不超过5M <span>最多 1 张</span>
            </div>
            <Form.Item
              label="群二维码"
              shouldUpdate
              required
              labelCol={{ span: 3 }}
              className={styles.formItem}
              wrapperCol={{ span: 21 }}
            >
              {({ getFieldValue, setFieldValue }) => {
                const groupCode = getFieldValue('groupCode');
                return (
                  <Form.Item
                    className={styles.formItem}
                    validateTrigger="onBlur"
                    getValueFromEvent={(e) => groupCode}
                    name="groupCode"
                    rules={[
                      {
                        required: true,
                        message: '请上传群二维码',
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={async (i: any) => {
                        const unit = i.file.name.split(',');
                        const file: any = await compressImgOrVideo(i.file);
                        if (file === false) {
                          return;
                        }
                        const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                        setFieldValue('groupCode', url);
                      }}
                      beforeUpload={beforeUpload}
                    >
                      {groupCode && typeof groupCode === 'string' ? (
                        <img
                          src={groupCode}
                          alt="avatar"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      ) : (
                        <button
                          style={{
                            border: 0,
                            background: 'none',
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>上传</div>
                        </button>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div className={styles.tips}>
              支持JPG、PNG，大小不超过5M <span>最多 1 张</span>
            </div>
            <Form.Item
              label="群聊天"
              shouldUpdate
              labelCol={{ span: 3 }}
              className={styles.formItem}
              validateTrigger="onBlur"
              wrapperCol={{ span: 21 }}
            >
              {({ getFieldValue, setFieldValue }) => {
                const groupChatList = getFieldValue('groupChatList');
                return (
                  <Form.Item
                    className={styles.formItem}
                    name="groupChatList"
                    getValueFromEvent={(e) => groupChatList.filter((i: any) => i.status !== 'removed')}
                    initialValue={[]}
                  >
                    <Upload
                      listType="picture-card"
                      fileList={groupChatList}
                      beforeUpload={beforeUpload}
                      maxCount={4}
                      // multiple
                      customRequest={async (i: any) => {
                        const unit = i.file.name.split(',');
                        const file: any = await compressImgOrVideo(i.file);
                        if (file === false) {
                          return;
                        }
                        const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                        setFieldValue('groupChatList', [
                          ...groupChatList,
                          {
                            uid: uniqueId(),
                            name: i.file.type,
                            status: 'done',
                            url,
                          },
                        ]);
                      }}
                      onRemove={(values) => {
                        setFieldValue('groupChatList', [...groupChatList.filter((item) => item.url !== values.url)]);
                      }}
                    >
                      {groupChatList && groupChatList.length >= 4 ? null : (
                        <button
                          style={{
                            border: 0,
                            background: 'none',
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>上传</div>
                        </button>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div className={styles.tips}>
              支持JPG、PNG，大小不超过5M <span>最多 4 张</span>
            </div>
            {/*<Form.Item*/}
            {/*  label="添加主播"*/}
            {/*  shouldUpdate*/}
            {/*  labelCol={{ span: 3 }}*/}
            {/*  className={styles.formItem}*/}
            {/*  wrapperCol={{ span: 21 }}*/}
            {/*>*/}
            {/*  {({ getFieldValue, setFieldValue }) => {*/}
            {/*    const groupChatList = getFieldValue('add1');*/}
            {/*    return (*/}
            {/*      <Form.Item*/}
            {/*        className={styles.formItem}*/}
            {/*        name="add1"*/}
            {/*        valuePropName="targetKeys"*/}
            {/*      >*/}
            {/*        <Transfer*/}
            {/*          dataSource={anchorList}*/}
            {/*          showSearch*/}
            {/*          render={(item) => `${item.title}`}*/}
            {/*          listStyle={{*/}
            {/*            width: 250,*/}
            {/*            height: 400,*/}
            {/*          }}*/}
            {/*        />*/}
            {/*      </Form.Item>*/}
            {/*    );*/}
            {/*  }}*/}
            {/*</Form.Item>*/}
          </div>
        </FullModal>
      </Form>
    );
  }
}
